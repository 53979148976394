export const POSTS_REQUEST = 'POSTS_REQUEST';
export const POSTS_LOAD = 'POSTS_LOAD';
export const POSTS_ERROR = 'POSTS_ERROR';
export const POST_SAVE = 'POST_SAVE';
export const POST_UPDATE = 'POST_UPDATE';

export function requestPosts()
{
    return {
        type: POSTS_REQUEST
    }
}

export function loadPosts(posts)
{
    return {
        type: POSTS_LOAD,
        payload: {
            posts
        }
    }
}

export function savePost(post)
{
    return {
        type: POST_SAVE,
        payload: {
            post
        }
    }

}

export function postsError(message)
{
    return {
        type: POSTS_ERROR,
        payload: {message}
    }
}