import { put, takeLatest, call, all } from "redux-saga/effects";
import { push } from "connected-react-router";
import { ROLES_REQUEST, ROLE_SAVE, loadRoles, rolesError, loadPermissions, ROLE_UPDATE } from "../actions/roles.actions";
import api from '../api';

export function* requestRoles(action)
{
    try {
        let result = yield call(api.listRoles);
        if (result.success) {

            result.data = result.data.map((role) => {
                role.permissions = role.permissions.map((perm) => perm.name);
                return role;
            })

            yield put(loadRoles(result.data));

            let permissions = yield call(api.listPermissions);
            yield put(loadPermissions(permissions.data));

        } else {
            yield put(rolesError(result.message));
        }
    } catch (e) {
        yield put(rolesError(e.message));
    }
    
}

export function* getRoles() {
    let result = yield call(api.listRoles);
    if (result.success) {

        result.data = result.data.map((role) => {
            role.permissions = role.permissions.map((perm) => perm.name);
            return role;
        })

        yield put(loadRoles(result.data));
    } else {
        yield put(rolesError(result.message));
    }
}

export function* saveRole(action) {
    let result = yield call(api.saveRole, action.payload.role);
    if (result.success) {

        yield getRoles();
        yield put(push('/dashboard/roles'));
        
    } else {

    }
}

export function* updateRoleAsync(action) {
    let result = yield call(api.updateRole, action.payload.id, action.payload.role);
    if (result.success) {
        yield getRoles();
        yield put(push('/dashboard/roles'));
    }
}

export function* watchRolesRequests() {
    yield takeLatest(ROLES_REQUEST, requestRoles);
}

export function* watchRoleSave() {
    yield takeLatest(ROLE_SAVE, saveRole);
}

export function* watchRoleUpdate() {
    yield takeLatest(ROLE_UPDATE, updateRoleAsync   );
}

export default function* rolesSaga() {
    yield all([
        watchRolesRequests(),
        watchRoleSave(),
        watchRoleUpdate(),
    ]);
}