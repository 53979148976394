import { put, takeLatest, call, all } from "redux-saga/effects";
import { SECTIONS_REQUEST, loadSections, SECTION_SAVE, setSectionSaveLoading, SECTION_UPDATE } from "../actions/sections.actions";
import api from '../api';


export function* requestSections(action) {
    let result = yield call(api.listSections);
    if (result.success) {
        yield put(loadSections(result.data))
    }
}

export function* doSaveSection(action) {
    yield put(setSectionSaveLoading());
    let result = yield call(api.addSection, action.payload.section);
    if (result.success) {
        yield put(loadSections(result.data));
    }
}

export function* doUpdateSection(action) {
    yield put(setSectionSaveLoading());
    let result = yield call(api.updateSection, action.payload.section);
    if (result.success) {
        yield put(loadSections(result.data));
    }
}

export function* watchSectionRequest() {
    yield takeLatest(SECTIONS_REQUEST, requestSections)
}

export function* watchSectionSave() {
    yield takeLatest(SECTION_SAVE, doSaveSection)
}

export function* watchSectionUpdateSave() {
    yield takeLatest(SECTION_UPDATE, doUpdateSection);
}

export default function* sectionsSaga() {
    yield all([
        watchSectionRequest(),
        watchSectionSave(),
        watchSectionUpdateSave(),
    ]);
}