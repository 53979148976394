export const ROLES_REQUEST = 'ROLES_REQUEST';
export const ROLES_LOAD = 'ROLES_LOAD';
export const ROLES_ERROR = 'ROLES_ERROR';
export const ROLE_SAVE = 'ROLES_SAVE';
export const ROLE_UPDATE = 'ROLE_UPDATE';
export const PERMISSIONS_REQUEST = 'PERMISSIONS_REQUEST';
export const PERMISSIONS_LOAD = 'PERMISSIONS_LOAD';
export const PERMISSIONS_ERROR = 'PERMISSIONS_ERROR';

export function requestRoles()
{
    return {
        type: ROLES_REQUEST
    }
}

export function loadRoles(roles)
{
    return {
        type: ROLES_LOAD,
        payload: {roles}
    }
}

export function rolesError(error)
{
    return {
        type: ROLES_ERROR,
        payload: {error}
    }
}

export function saveRole(role)
{
    return {
        type: ROLE_SAVE,
        payload: {role}
    }
}

export function updateRole(role)
{
    return {
        type: ROLE_UPDATE,
        payload: {
            id: role.id,
            role: role,
        }
    }
}

export function requestPermissions()
{
    return {
        type: PERMISSIONS_REQUEST
    }
}

export function loadPermissions(permissions)
{
    return {
        type: PERMISSIONS_LOAD,
        payload: {permissions}
    }
}