import { put, takeLatest, call, all, delay } from "redux-saga/effects";
import { push } from "connected-react-router";
import { POST_ADD, POST_SAVE, loadPostSkeleton, POST_EDIT, loadPost, POST_UPDATE, POST_UNPUBLISH } from "../actions/currentPost.actions";
import { requestPosts } from "../actions/posts.actions";
import api from '../api';

export function* loadPostAdd() {
    yield put(loadPostSkeleton());
    yield put(push('/dashboard/posts/add'));
}

export function* loadPostEdit(action) {
    let result = yield call(api.getPost, action.payload.id);
    
    if (result.success) {
        yield put(loadPost(result.data));
        yield put(push('/dashboard/posts/edit'));
    }
}

export function* savePost(action) {
    let result = yield call(api.savePost, action.payload.post);
    if (result.success) {

        if (action.payload.publish) {
            yield call(api.publishPost, result.data.id);
        }
        
        yield put(push('/dashboard/posts'));
        yield delay(2000);
        yield put(requestPosts());
        
    } else {

    }
}

export function* unpublishPost(action) {
    let result = yield call(api.unpublishPost, action.payload.id);
    if (result.success) {
        yield put(push('/dashboard/posts'));
        yield delay(2000);
        yield put(requestPosts());
    }
}

export function* updatePost(action) {
    let result = yield call(api.updatePost, action.payload.id, action.payload.post);
    if (result.success) {

        if (action.payload.publish) {
            yield call(api.publishPost, result.data.id);
        }

        yield put(push('/dashboard/posts'));
        
        yield delay(2000);
        yield requestPosts();
        
    } else {

    }
}

export function* watchAddRequest() {
    yield takeLatest(POST_ADD, loadPostAdd);
}

export function* watchEditRequest() {
    yield takeLatest(POST_EDIT, loadPostEdit);
}

export function* watchSavePost() {
    yield takeLatest(POST_SAVE, savePost);
}

export function* watchUpdatePost() {
    yield takeLatest(POST_UPDATE, updatePost);
}

export function* watchUnpublishPost() {
    yield takeLatest(POST_UNPUBLISH, unpublishPost);
}

export default function* currentPostSaga() {
    yield all([
        watchAddRequest(),
        watchEditRequest(),
        watchSavePost(),
        watchUpdatePost(),
        watchUnpublishPost(),
    ]);
}