import { all } from "redux-saga/effects";
import authSaga from './auth.saga';
import usersSaga from './users.saga';
import rolesSaga from './roles.saga';
import sectionsSaga from "./sections.saga";
import postsSaga from "./posts.saga";
import currentPostSaga from "./currentPost.saga";
import configurationSaga from "./configuration.saga";
import layoutSaga from "./layout.saga";
import posthistorySaga from "./posthistory.saga";

export default function* rootSaga() {
    yield all([
        authSaga(),
        usersSaga(),
        rolesSaga(),
        sectionsSaga(),
        postsSaga(),
        currentPostSaga(),
        configurationSaga(),
        layoutSaga(),
        posthistorySaga(),
    ]);
 }