import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import auth from './auth.reducer';
import userlist from './users.reducer';
import roles from './roles.reducer';
import postlist from './posts.reducer';
import sectionlist from './sections.reducer';
import currentPost from "./currentPost.reducer";
import configuration from "./configuration.reducer";
import layouts from "./layout.reducer";
import posthistory from "./posthistory.reducer";

const createRootReducer = (history) => combineReducers({
    router: connectRouter(history),
    auth,
    userlist,
    roles,
    postlist,
    sectionlist,
    currentPost,
    configuration,
    layouts,
    posthistory,
})

export default createRootReducer