import { put, takeLatest, call, all } from "redux-saga/effects";
import api from '../api';
import { CONFIGURATION_REQUEST, loadConfig, CONFIGURATION_SAVE, savingConfig } from "../actions/configuration.actions";

export function* requestConfig()
{
    let response = yield call(api.getConfiguration);

    if (response.success) {
        yield put(loadConfig(response.data));
    }
}

export function* saveConfig(action)
{
    yield put(savingConfig(true));
    let response = yield call(api.saveConfiguration, action.payload.config);
    if (response.success) {
        yield put(savingConfig(false))
    }
}

export function* watchConfigRequest()
{
    yield takeLatest(CONFIGURATION_REQUEST, requestConfig);
}

export function* watchConfigSave()
{
    yield takeLatest(CONFIGURATION_SAVE, saveConfig);
}

export default function* configurationSaga()
{
    yield all([
        watchConfigRequest(),
        watchConfigSave(),
    ]);
}