import React from "react";
import AppLogin from "../containers/AppLogin";
import './login.page.css';
import AppVersion from "../AppVersion";

export const LoginPage = () => {
    return (
        <div className="LoginPage">
            <div className="LoginPage-form">
                <h1 className="LoginPage__logo">{process.env.REACT_APP_NAME}</h1>
                <AppLogin />
                <AppVersion />
            </div>
        </div>
    );
}
