import React from 'react';

export const AppVersion = () => {
    return (
        <div style={{textAlign: 'center'}}>
            <span role="img" aria-label="cotil">🌱</span> Cotil v{process.env.REACT_APP_VERSION}
        </div>
    )
}

export default AppVersion;