import { put, takeLatest, call, all, delay } from "redux-saga/effects";
import { push } from "connected-react-router";
import { LOGIN_ATTEMPT, doLogin, loginError, setPermissions, setProfile, AUTOLOGIN_ATTEMPT, LOGOUT_ATTEMPT, doLogout, setLoggingIn, LOGIN_REFRESH } from "../actions/auth.actions";
import { requestSections } from '../actions/sections.actions'
import api from '../api';
import jwt, { TokenExpiredError } from 'jsonwebtoken';
import { loadMetafields } from "../actions/configuration.actions";
import { toast } from "react-toastify";
import { TOAST_ERROR_OPTIONS } from "../constants/toastify";
import moment from "moment";

const local_token = 'ctl_token';
const public_key = JSON.parse(`"${process.env.REACT_APP_API_PUBLIC_KEY}"`);

export function getTokenScopes(token) {
    return new Promise((resolve, reject) => {
        try {
            let decoded = jwt.verify(token, public_key);
            resolve(decoded.scopes);
        } catch (err) {
            reject(err.name === TokenExpiredError.name ? 'Login expirado' : err.message);
        }
    });
}

export function* fetchInitialData()
{
    yield put(requestSections())

    let response = yield call(api.getMetafields);

    if (response.success) {
        yield put(loadMetafields(response.data));
    }
    
    
    let profile = yield call(api.getProfile);
    yield put(setProfile(profile.data));
}

export function* attemptLogin(action)
{
    yield put(setLoggingIn(true));
    try {
        let loginResult = yield call(api.login, action.payload.username, action.payload.password);
        if (loginResult.success) {

            yield delay(1000);

            yield put(doLogin(loginResult.data));
            api.setToken(loginResult.data);
            localStorage.setItem(local_token, loginResult.data);

            let permissions = yield call(getTokenScopes, loginResult.data);

            yield put(setPermissions(permissions));

            yield fetchInitialData();

            yield put(setLoggingIn(false));

            yield put(push('/dashboard'));
        } else {
            yield put(loginError(loginResult.message));
            yield put(setLoggingIn(false));
        }
    } catch (e) {
        yield put(loginError(e.message));
        yield put(setLoggingIn(false));
    }
}

export function* attemptAutologin(action)
{
    let savedToken = localStorage.getItem(local_token);

    let tokenIsValid = false;

    let errorMessage = null;
 
    // TODO: Validate token expire
    if (savedToken != null) {

        yield put(setLoggingIn(true));
        try {
            jwt.verify(savedToken, public_key);
            tokenIsValid = true;
        } catch (err) {
            errorMessage = err.name === TokenExpiredError.name ? 'Login expirado' : err.message;
            localStorage.removeItem(local_token);
            yield put(setLoggingIn(false));
        }

        if (tokenIsValid) {
            try {
                api.setToken(savedToken);
        
                let permissions = yield call(getTokenScopes, savedToken);
    
                if (permissions.length > 0) {
                    yield put(doLogin(savedToken));
    
                    yield put(setPermissions(permissions));
    
                    yield fetchInitialData();
                    
                    yield put(setLoggingIn(false));

                    yield put(push('/dashboard'));
                } else {
                    yield put(loginError('Token expired'));
                    yield put(setLoggingIn(false));
                }
            } catch(e) {
                console.log(e);
                yield put(loginError(e));
                yield put(setLoggingIn(false));
            }
        } else {
            yield put(loginError(errorMessage));
            yield put(setLoggingIn(false));
        }
    }
}

export function* refreshLogin() {
    let refresh = yield call(api.loginRefresh);

    if (refresh.success) {
        let token = refresh.data;
        yield put(doLogin(token));
        api.setToken(token);
        localStorage.setItem(local_token, token);

        try {
            let decoded = jwt.verify(token, public_key);
            let now = moment();
            let expire = moment.unix(decoded.exp);
            toast.info(`Tu sesión se ha renovado. Ahora expira ${now.to(expire)}`);
        } catch (err) {
            let errorMessage = err.name === TokenExpiredError.name ? 'Login expirado' : err.message;
            localStorage.removeItem(local_token);
            yield put(setLoggingIn(false));
            toast.error(errorMessage, TOAST_ERROR_OPTIONS);
            yield put(push('/login'));
        }

    }
}

export function* attemptLogout(action)
{
    localStorage.removeItem(local_token);
    yield put(doLogout());
}

export function* watchLoginAttempts() {
    yield takeLatest(LOGIN_ATTEMPT, attemptLogin);
}

export function* watchAutoLoginAttempts() {
    yield takeLatest(AUTOLOGIN_ATTEMPT, attemptAutologin);
}

export function* watchLogoutAttempts() {
    yield takeLatest(LOGOUT_ATTEMPT, attemptLogout);
}

export function* watchLoginRefresh() {
    yield takeLatest(LOGIN_REFRESH, refreshLogin);
}

export default function* authSaga() {
    yield all([
        watchLoginAttempts(),
        watchAutoLoginAttempts(),
        watchLogoutAttempts(),
        watchLoginRefresh(),
    ]);
}