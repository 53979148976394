
//export const getToken = (state) => state.auth.token;

class API
{
    host = process.env.REACT_APP_API_HOST;
    token = null;

    setToken(token) {
        this.token = token;
    }

    /**
     * Authentication
     */
    login = (email, password) => {
        return this.post('/login', {email, password, scope: '*'})
    }

    loginRefresh = () => {
        return this.get('/login/refresh');
    }

    getPermissions = () => {
        return this.get('/profile/permissions');
    }

    getProfile = () => {
        return this.get('/profile');
    }

    /**
     * Users
     */
    listUsers = () => {
        return this.get('/users');
    }

    listRoles = () => {
        return this.get('/roles');
    }

    listPermissions = () => {
        return this.get('/permissions');
    }

    saveRole = (role) => {
        return this.post('/roles', role);
    }

    updateRole = (id, role) => {
        return this.put(`/roles/${id}`, role);
    }

    /**
     * Users
     */
    saveUser = (user) => {
        return this.post('/users', user);
    }

    updateUser = (id, user) => {
        return this.put(`/users/${id}`, user);
    }

    /**
     * Sections
     */
    listSections = () => {
        return this.get('/sections');
    }

    addSection = (name) => {
        return this.post(`/sections`, {name})
    }

    updateSection = (section) => {
        return this.put(`/sections/${section.id}`, section)
    }

    /**
     * Posts
     */
    savePost = (post) => {
        return this.post('/posts', post);
    }

    updatePost = (id, post) => {
        return this.put(`/posts/${id}`, post);
    }
    
    publishPost = (id) => {
        return this.post(`/posts/${id}`);
    }

    unpublishPost = (id) => {
        return this.post(`/posts/unpublish/${id}`);
    }

    listPosts = () => {
        return this.get('/posts');
    }

    getPost = (id) => {
        return this.get(`/posts/${id}`)
    }

    getPostHistory = (id) => {
        return this.get(`/history/${id}`);
    }

    getPostVersion = (id, version) => {
        return this.get(`/history/${id}/${version}`);
    }

    /**
     * Oembed
     */
    getOembed = (url) => {
        return this.get(`/oembed?url=${url}`)
    }

    /**
     * Images
     */
    updateImage = (id, data) => {
        return this.put(`/images/${id}`, data);
    }
    
    getLastImages = () => {
        return this.get('/images/latest');
    }

    searchImages = (query) => {
        return this.post('/images/search', {query});
    }

    /**
     * Layouts
     */
    getLayouts = () => {
        return this.get('/layouts');
    }

    getLayout = (id) => {
        return this.get(`/layouts/${id}`);
    }

    createLayout = (layout) => {
        return this.post('/layouts', layout);
    }

    updateLayout = (id, layout) => {
        return this.put(`/layouts/${id}`, layout);
    }

    /**
     * Tags
     */
    getTags = () => {
        return this.get('/tags');
    }

    /**
     * Configuration
     */
    getConfiguration = () => {
        return this.get('/configuration');
    }

    saveConfiguration = (config) => {
        let configData = config.map(({key, data}) => {
            return {
                key,
                value: data.value
            };
        })
        return this.put('/configuration', {config: configData});
    }

    getMetafields = () => {
        return this.get('/metafields');
    }

    /**
     * Common methods
     */
    get(path, params) {
        return fetch(this.host + path, {
            headers: {
                'Content-Type': 'application/json',
                //'X-Requested-With': 'XMLHttpRequest',
                'Authorization': 'Bearer ' + this.token,
            },
            body: JSON.stringify(params),
        }).then(resp => resp.json());
    }

    post(path, params) {
        return fetch(this.host + path, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                //'X-Requested-With': 'XMLHttpRequest',
                'Authorization': 'Bearer ' + this.token,
            },
            body: JSON.stringify(params),
        }).then(resp => resp.json());
    }

    put(path, params) {
        return fetch(this.host + path, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                //'X-Requested-With': 'XMLHttpRequest',
                'Authorization': 'Bearer ' + this.token,
            },
            body: JSON.stringify(params),
        }).then(resp => resp.json());
    }

}

const api = new API();

export default api;