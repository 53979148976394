export const SECTIONS_REQUEST = 'SECTIONS_REQUEST';
export const SECTIONS_LOAD = 'SECTIONS_LOAD';
export const SECTIONS_ERROR = 'SECTIONS_ERROR';
export const SECTION_SAVE = 'SECTION_SAVE';
export const SECTION_SAVE_LOADING = 'SECTION_SAVE_LOADING';
export const SECTION_UPDATING = 'SECTION_UPDATING';
export const SECTION_LOCAL_UPDATE = 'SECTION_LOCAL_UPDATE';
export const SECTION_UPDATE = 'SECTION_UPDATE';

export function requestSections()
{
    return {
        type: SECTIONS_REQUEST
    }
}

export function loadSections(sections)
{
    return {
        type: SECTIONS_LOAD,
        payload: {
            sections
        }
    }
}

export function saveSection(section) {
    return {
        type: SECTION_SAVE,
        payload: {
            section
        }
    }
}

export function updateSection(section)
{
    return {
        type: SECTION_UPDATE,
        payload: {
            section
        }
    }
}

export function localUpdateSection(section)
{
    return {
        type: SECTION_LOCAL_UPDATE,
        payload: {
            section
        }
    }
}

export function setUpdateSection(id)
{
    return {
        type: SECTION_UPDATING,
        payload: {
            id
        }
    }
}

export function setSectionSaveLoading() {
    return {
        type: SECTION_SAVE_LOADING
    }
}

/*
export function userlistError(error)
{
    return {
        type: USERLLIST_ERROR,
        payload: {
            error
        }
    }
}

export function saveUser(user)
{
    return {
        type: USER_SAVE,
        payload: {user}
    }
}

export function updateUser(user)
{
    return {
        type: USER_UPDATE,
        payload: {
            id: user.id,
            user: user,
        }
    }
}*/