import { produce } from "immer";
import { POSTHISTORY_LOAD, POSTHISTORY_LOADING, POSTHISTORY_LOAD_VERSION, POSTHISTORY_VERSION_LOADING } from "../actions/posthistory.actions";

const initialState = {
    postId: null,
    version: null,
    history: [],
    postLoading: false,
    historyLoading: false,
}

export default (state = initialState, action) =>
produce(state, draft => {
    switch (action.type) {
        case POSTHISTORY_LOAD:
            draft.history = action.payload.history;
            break;
        case POSTHISTORY_LOADING:
            draft.historyLoading = action.payload.loading;
            break;
        case POSTHISTORY_LOAD_VERSION:
            draft.version = action.payload.version;
            break;
        case POSTHISTORY_VERSION_LOADING:
            draft.postLoading = action.payload.loading;
            break;
        default:
            return state;
    }
})