import produce from 'immer';
import { CONFIGURATION_LOAD, CONFIGURATION_UPDATE_KEY, METAFIELDS_LOAD, CONFIGURATION_SAVING } from '../actions/configuration.actions';

const initialState = {
    config: [],
    saving: false,
    metafields: []
};

export default (state = initialState, action) =>
produce (state, draft => {
    switch (action.type) {
        case CONFIGURATION_LOAD:
            draft.config = action.payload.config;
        break;
        case CONFIGURATION_SAVING:
            draft.saving = action.payload.saving;
        break;
        case METAFIELDS_LOAD:
            draft.metafields = action.payload.metafields;
        break;
        case CONFIGURATION_UPDATE_KEY:
            draft.config = draft.config.map((item) => {
                if (item.key === action.payload.key) {
                    item.data.value = action.payload.value;
                }

                return item;
            });
        break;
        default:
            return state
    }
})