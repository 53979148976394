export const LOGIN_ATTEMPT = 'LOGIN_ATTEMPT';
export const AUTOLOGIN_ATTEMPT = 'AUTOLOGIN_ATTEMPT';
export const DO_LOGIN = 'DO_LOGIN';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGOUT_ATTEMPT = 'LOGOUT_ATTEMPT';
export const DO_LOGOUT = 'DO_LOGOUT';
export const SET_PERMISSIONS = 'SET_PERMISSIONS';
export const SET_PROFILE = 'SET_PROFILE';
export const SET_LOGGING_IN = 'SET_LOGGING_IN';
export const LOGIN_REFRESH = 'LOGIN_REFRESH';


export function setLoggingIn(status)
{
    return {
        type: SET_LOGGING_IN,
        payload: {status},
    }
}

export function refreshLogin()
{
    return {
        type: LOGIN_REFRESH
    }
}

export function login(username, password)
{
    return {
        type: LOGIN_ATTEMPT,
        payload: {
            username,
            password
        }
    }
}

export function autoLogin()
{
    return {
        type: AUTOLOGIN_ATTEMPT
    }
}

export function doLogin(token)
{
    return {
        type: DO_LOGIN,
        payload: {
            token
        }
    }
}

export function logout()
{
    return {
        type: LOGOUT_ATTEMPT
    }
}

export function doLogout()
{
    return {
        type: DO_LOGOUT
    };
}

export function loginError(error)
{
    return {
        type: LOGIN_ERROR,
        payload: {
            error
        }
    }
}

export function setPermissions(permissions)
{
    return {
        type: SET_PERMISSIONS,
        payload: {
            permissions
        }
    }
}

export function setProfile(profile)
{
    return {
        type: SET_PROFILE,
        payload: { profile }
    };
}