import {
    USERLIST_REQUEST,
    USERLIST_LOAD,
    USERLLIST_ERROR,
} from "../actions/users.actions";
import produce from 'immer';

const initialState = {
    loadingUsers: false,
    error: null,
    users: [],
}

export default (state = initialState, action) =>
    produce (state, draft => {
        switch (action.type) {
            case USERLIST_REQUEST:
                draft.loadingUsers = true;
            break;
            case USERLIST_LOAD:
                draft.loadingUsers = false;
                draft.users = action.payload.users;
            break;
            case USERLLIST_ERROR:
                draft.loadingUsers = false;
                draft.error = action.payload.error;
            break;
            default:
                return state
        }
    })