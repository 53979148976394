import produce from 'immer';
import { LAYOUTS_LOAD, LAYOUT_LOAD_EMPTY, LAYOUT_UPDATE_KEY, LAYOUT_ADD_BLOCK, LAYOUT_UPDATE_BLOCK } from '../actions/layout.actions';

export const LAYOUT_BLOCK_MANUAL = 'manual';
export const LAYOUT_BLOCK_AUTOSINGLE = 'auto-single';
export const LAYOUT_BLOCK_AUTOLIST = 'auto-list';

const emptyLayout = {
    id: null,
    key: '',
    layout: []
};

const defaultBlock = {
    slug: '',
    type: LAYOUT_BLOCK_MANUAL,
    count: 2,
    skip: 0,
    exclude_previous: true
};

const initialState = {
    layouts: [],
    isLoading: false,
    currentLayout: []
};

export default (state = initialState, action) =>
produce (state, draft => {
    switch (action.type) {
        case LAYOUT_LOAD_EMPTY:
            draft.currentLayout = emptyLayout;
            break;
        case LAYOUT_UPDATE_KEY:
            draft.currentLayout.key = action.payload.key;
            break;
        case LAYOUT_ADD_BLOCK:
            draft.currentLayout.layout.push(defaultBlock);
            break;
        case LAYOUT_UPDATE_BLOCK:
            draft.currentLayout.layout[action.payload.index] = action.payload.data;
            break;
        case LAYOUTS_LOAD:
            draft.layouts = action.payload.layouts;
        break;
        default:
            return state
    }
})