import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { login, autoLogin } from '../actions/auth.actions';
import { useFormInput } from "../hooks/forms";
import './AppLogin.css';
import { Spinner } from "reactstrap";

export const AppLogin = () => {

    const error = useSelector((state) => state.auth.loginError);
    const isLoggingIn = useSelector((state) => state.auth.isLoggingIn);
    const username = useFormInput('');
    const password = useFormInput('');

    const dispatch = useDispatch();

    useEffect(() => {
            dispatch(autoLogin());
    }, [dispatch]);

    function handleSubmit(e) {
        e.preventDefault();
        dispatch(login(username.value, password.value));
    }

    return (
        <form className="LoginForm" onSubmit={handleSubmit}>
            {   error !== null &&
                <div className="LoginForm__section LoginForm__section--error">
                    {error}
                </div>
            }
            
            <input
                className="LoginForm__section LoginForm__section--input"
                type="username" autoFocus 
                placeholder="Email"
                {...username}
            />
            <input
                className="LoginForm__section LoginForm__section--input"
                type="password"
                placeholder="Password"
                {...password}
            />
            <button className={`LoginForm__section LoginForm__section--submit ${isLoggingIn && 'LoginForm__section--loading'}`}>
                Ingresar
                {isLoggingIn && (<Spinner size="sm" className="ml-2" color="light" />)}
            </button>
        </form>
    )
}

export default AppLogin;