import produce from 'immer';
import { MODULE_HTML } from "../constants/modules";
import {
    POST_LOAD_SKELETON,
    POST_UPDATE_FIELD,
    POST_UPDATE_METAFIELD,
    POST_CONTENT_ADD_MODULE,
    POST_CONTENT_UPDATE_MODULE,
    POST_LOAD,
    POST_CONTENT_DELETE_MODULE,
    POST_CONTENT_SORT_MODULE,
} from "../actions/currentPost.actions";
import uniqid from "uniqid"

const postSkeleton = {
    id: null,
    title: '',
    section_id: 0,
    image: null,
    content: [
        {
            type: MODULE_HTML,
            data: {
                html: ""
            }
        }
    ],
    metadata: {}
}

const initialState = {
    id: null,
    title: '',
    content: []
};

export default (state = initialState, action) =>
produce (state, draft => {
    switch (action.type) {
        case POST_CONTENT_ADD_MODULE:
            let newElement = {
                id: '',
                tmp_id: uniqid(),
                image_id: null,
                type: action.payload.type,
                data: null
            };

            if (action.payload.type === MODULE_HTML) {
                newElement.data = {
                    html: ''
                };
            }

            draft.content.splice(action.payload.index + 1, 0, newElement)
            return draft;
        case POST_CONTENT_UPDATE_MODULE:
            draft.content[action.payload.index].type = action.payload.type;
            draft.content[action.payload.index].data = action.payload.data;
            return draft;
        case POST_CONTENT_DELETE_MODULE:
            draft.content.splice(action.payload.index, 1);
            break;
        case POST_CONTENT_SORT_MODULE:
            let origin = action.payload.index;
            let dest = action.payload.dest;
            let aux = draft.content[origin];
            if(draft.content[dest]) {

                draft.content[origin].order = dest;
                draft.content[dest].order = origin;
                draft.content.splice(origin, 1);
                draft.content.splice(dest, 0, aux);

                if(origin > dest) {
                    for (let i = dest; i <= origin; i++ ) {
                        draft.content[i].order = i;
                    }
                } else if (origin < dest) {
                    for (let i = origin; i <= dest; i++ ) {
                        draft.content[i].order = i;
                    }
                }
            }
            break;
        case POST_LOAD:
            draft = action.payload.post;
            return draft;
        case POST_LOAD_SKELETON:
            draft = postSkeleton;
            return draft;
        case POST_UPDATE_FIELD:
            draft[action.payload.field] = action.payload.value;
            break;
        case POST_UPDATE_METAFIELD:
            draft.metadata = {...state.metadata, [action.payload.key]: action.payload.value};
            break;
        default:
            return state
    }
})