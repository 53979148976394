import React from "react";
import { useSelector } from "react-redux";

const Allowed = ({to = null, some = null, every = null, children}) => {

    const permissions = useSelector((state) => state.auth.permissions);
    let isAllowed = false;

    let hasPermission = (perm) => permissions.indexOf(perm) !== -1;

    if (every !== null) {
        isAllowed = every.every(hasPermission);
    }

    if (some !== null) {
        isAllowed = some.some(hasPermission)
    }

    if (to !== null) {
        isAllowed = hasPermission(to);
    }

    return isAllowed ?  <>{children}</> : null;
}

export default Allowed;