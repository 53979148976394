export const USERLIST_REQUEST = 'USERLIST_REQUEST';
export const USERLIST_LOAD = 'USERLIST_LOAD';
export const USERLLIST_ERROR = 'USERLIST_ERROR';
export const USER_SAVE = 'USER_SAVE';
export const USER_UPDATE = 'USER_UPDATE';

export function requestUserlist()
{
    return {
        type: USERLIST_REQUEST
    }
}

export function loadUserlist(users)
{
    return {
        type: USERLIST_LOAD,
        payload: {
            users
        }
    }
}

export function userlistError(error)
{
    return {
        type: USERLLIST_ERROR,
        payload: {
            error
        }
    }
}

export function saveUser(user)
{
    return {
        type: USER_SAVE,
        payload: {user}
    }
}

export function updateUser(user)
{
    return {
        type: USER_UPDATE,
        payload: {
            id: user.id,
            user: user,
        }
    }
}