import { put, takeLatest, call, all } from "redux-saga/effects";
import { push } from "connected-react-router";
import { POSTS_REQUEST, POST_SAVE, loadPosts, postsError } from "../actions/posts.actions";
import api from '../api';
import { toast } from "react-toastify";
import { TOAST_ERROR_OPTIONS } from "../constants/toastify";

export function* requestPosts(action)
{
    try {
        let result = yield call(api.listPosts);
        if (result.success) {
            yield put(loadPosts(result.data));
        } else {
            yield toast.error(result.message, TOAST_ERROR_OPTIONS);
            yield put(postsError(result.message));
        }
    } catch (e) {
        yield toast.error(e.message, TOAST_ERROR_OPTIONS);
        yield put(postsError(e.message));
    }
    
}


export function* savePost(action) {
    let result = yield call(api.savePost, action.payload.post);
    if (result.success) {

        yield requestPosts();
        yield put(push('/dashboard/posts'));
        
    } else {

    }
}

export function* watchPostsRequest() {
    yield takeLatest(POSTS_REQUEST, requestPosts);
}

export function* watchPostSave() {
    yield takeLatest(POST_SAVE, savePost);
}

export default function* postsSaga() {
    yield all([
        watchPostsRequest(),
    ]);
}