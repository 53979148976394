import React from "react";
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../actions/auth.actions";
import { Nav, DropdownToggle, DropdownMenu } from 'reactstrap';

import {
    AppAsideToggler,
    AppHeader,
    AppHeaderDropdown,
    AppSidebarToggler,
  } from '@coreui/react'

const Header = () => {

    const username = useSelector((state) => state.auth.isAuthenticated && state.auth.profile.name);
    const dispatch = useDispatch();

    return (
        <AppHeader fixed>
          <AppSidebarToggler className="d-lg-none" display="md" mobile />

          <Link className="navbar-brand" to="/dashboard">{process.env.REACT_APP_NAME}</Link>

          <AppSidebarToggler className="d-md-down-none" display="lg" />

          <Nav className="ml-auto" navbar>
              {/*
            <NavItem className="d-md-down-none">
              <NavLink href="#"><i className="cui-bell icons font-xl d-block"></i><Badge pill color="danger">5</Badge></NavLink>
            </NavItem>
            <NavItem className="d-md-down-none">
              <NavLink href="#"><i className="cui-list icons icons font-xl d-block"></i></NavLink>
            </NavItem>
            <NavItem className="d-md-down-none">
              <NavLink href="#"><i className="cui-location-pin icons icons font-xl d-block"></i></NavLink>
            </NavItem>
              */}

            <AppHeaderDropdown>
              <DropdownToggle nav>
                {username}
              </DropdownToggle>
              <DropdownMenu right>
                <Link className="dropdown-item" to="/dashboard/profile">Perfil</Link>
                <button className="dropdown-item" onClick={ () => dispatch(logout()) }>Cerrar sesión</button>
              </DropdownMenu>
            </AppHeaderDropdown>
          </Nav>

          <AppAsideToggler className="d-md-down-none" />
          <AppAsideToggler className="d-lg-none" mobile />
        </AppHeader>
    )
}

export default Header;