export const CONFIGURATION_REQUEST = 'CONFIGURATION_REQUEST';
export const CONFIGURATION_LOAD = 'CONFIGURATION_LOAD';
export const CONFIGURATION_UPDATE_KEY = 'CONFIGURATION_UPDATE_KEY';
export const CONFIGURATION_SAVE = 'CONFIGURATION_SAVE';
export const CONFIGURATION_SAVING = 'CONFIGURATION_SAVING';

export const METAFIELDS_LOAD = 'METAFIELDS_LOAD';


export function requestConfig()
{
    return {
        type: CONFIGURATION_REQUEST
    }
}

export function loadConfig(config)
{
    return {
        type: CONFIGURATION_LOAD,
        payload: {
            config
        }
    }
}

export function loadMetafields(metafields)
{
    return {
        type: METAFIELDS_LOAD,
        payload: {
            metafields
        }
    }
}

export function updateConfig(key, value)
{
    return {
        type: CONFIGURATION_UPDATE_KEY,
        payload: {
            key,
            value
        }
    }
}

export function savingConfig(saving)
{
    return {
        type: CONFIGURATION_SAVING,
        payload: {saving}
    }
}

export function saveConfig(config)
{
    return {
        type: CONFIGURATION_SAVE,
        payload: {
            config
        }
    }
}