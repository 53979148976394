import React from "react";
import { Link } from 'react-router-dom';
import Allowed from "../containers/Allowed";
import { Nav, NavItem } from 'reactstrap'
import {
    AppSidebar,
    AppSidebarFooter,
    AppSidebarForm,
    AppSidebarHeader,
    AppSidebarMinimizer,
    // AppSidebarNav as AppSidebarNav,
    //AppSidebarNav2 as AppSidebarNav,
} from '@coreui/react'
import PerfectScrollbar from 'react-perfect-scrollbar';

const Sidebar = ({match}) => {

    return (
        <AppSidebar fixed display="lg">
            <AppSidebarHeader />
            <AppSidebarForm />
            {/*<AppSidebarNav navConfig={navigation} {...this.props} router={router}/>*/}
            <PerfectScrollbar>
                <Nav>
                    <Allowed to="post-list">
                        <NavItem><Link className="nav-link" to="/dashboard/posts">Notas</Link></NavItem>
                    </Allowed>
                    <Allowed some={["user-list", "role-list", "section-manage"]}>
                        <li className="nav-title">Administración</li>
                        <Allowed to="user-list">
                            <NavItem><Link className="nav-link" to="/dashboard/users">Usuarios</Link></NavItem>
                        </Allowed>
                        <Allowed to="role-list">
                            <NavItem><Link className="nav-link" to="/dashboard/roles">Roles</Link></NavItem>
                        </Allowed>
                        <Allowed to="section-manage">
                            <NavItem><Link className="nav-link" to="/dashboard/sections">Secciones</Link></NavItem>
                        </Allowed>
                    </Allowed>
                    <Allowed some={["site-manage"]}>
                        <li className="nav-title">Diseño</li>
                        <Allowed to="site-manage">
                            <NavItem><Link className="nav-link" to="/dashboard/admin/layouts">Plantillas</Link></NavItem>
                        </Allowed>
                    </Allowed>
                    <Allowed some={["site-manage"]}>
                        <li className="nav-title">Configuración</li>
                        <Allowed to="site-manage">
                            <NavItem><Link className="nav-link" to="/dashboard/admin/configuration">Configuración</Link></NavItem>
                        </Allowed>
                    </Allowed>
                </Nav>
            </PerfectScrollbar>

            <AppSidebarFooter />
            <AppSidebarMinimizer />
        </AppSidebar>
    )
}

export default Sidebar;