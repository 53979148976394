export const POST_ADD = 'POST_ADD';
export const POST_EDIT = 'POST_EDIT';
export const POST_LOAD = 'POST_LOAD';
export const POST_LOAD_SKELETON = 'POST_LOAD_SKELETON';
export const POST_UPDATE_FIELD = 'POST_UPDATE_FIELD';
export const POST_UPDATE_METAFIELD = 'POST_UPDATE_METAFIELD';
export const POST_SAVE = 'POST_SAVE';
export const POST_UNPUBLISH = 'POST_UNPUBLISH';
export const POST_UPDATE = 'POST_UPDATE';

export const POST_CONTENT_ADD_MODULE = 'POST_CONTENT_ADD_MODULE';
export const POST_CONTENT_UPDATE_MODULE = 'POST_CONTENT_UPDATE_MODULE';
export const POST_CONTENT_DELETE_MODULE = 'POST_CONTENT_DELETE_MODULE';
export const POST_CONTENT_SORT_MODULE = "POST_CONTENT_SORT_MODULE";

export function addModule(index, type) {
    return {
        type: POST_CONTENT_ADD_MODULE,
        payload: {
            index,
            type
        }
    }
}

export function updateModule(index, type, data) {
    return {
        type: POST_CONTENT_UPDATE_MODULE,
        payload: {
            index,
            type,
            data
        }
    }
}

export function deleteModule(index)
{
    return {
        type: POST_CONTENT_DELETE_MODULE,
        payload: {
            index
        }
    }
}

export function sortModule(index, dest) {
    return {
        type: POST_CONTENT_SORT_MODULE,
        payload: {
            index,
            dest
        }
    }
}

export function loadPostSkeleton() {
    return {
        type: POST_LOAD_SKELETON
    }
}

export function addPost()
{
    return {
        type: POST_ADD
    }
}

export function editPost(id)
{
    return {
        type: POST_EDIT,
        payload: {
            id
        }
    }
}

export function updatePost(id, post, publish = false)
{
    return {
        type: POST_UPDATE,
        payload: {
            id,
            post,
            publish
        }
    }
}

export function updatePostField(field, value)
{
    return {
        type: POST_UPDATE_FIELD,
        payload: {
            field,
            value
        }
    }
}

export function updateMetafield(key, value)
{
    return {
        type: POST_UPDATE_METAFIELD,
        payload: {
            key,
            value
        }
    }
}

export function loadPost(post) {
    return {
        type: POST_LOAD,
        payload: { post }
    }
}

export function savePost(post, publish = false)
{
    return {
        type: POST_SAVE,
        payload: {
            post,
            publish
        }
    }

}

export function unpublishPost(id)
{
    return {
        type: POST_UNPUBLISH,
        payload: {
            id
        }
    }
}