import { put, takeLatest, call, all } from "redux-saga/effects";
import { push } from "connected-react-router";
import { USERLIST_REQUEST, loadUserlist, userlistError, USER_SAVE, USER_UPDATE } from "../actions/users.actions";
import api from '../api';

export function* requestUserlist()
{
    try {
        let result = yield call(api.listUsers);
        if (result.success) {
            yield put(loadUserlist(result.data));
        } else {
            yield put(userlistError(result.message));
        }
    } catch (e) {
        yield put(userlistError(e.message));
    }
    
}

export function* saveUser(action) {
    let result = yield call(api.saveUser, action.payload.user);
    if (result.success) {

        yield requestUserlist();
        yield put(push('/dashboard/users'));
        
    } else {
        console.log(result);
    }
}

export function* updateUser(action) {
    let result = yield call(api.updateUser, action.payload.id, action.payload.user);
    if (result.success) {
        yield requestUserlist();
        yield put(push('/dashboard/users'));
    }
}

export function* watchUserlistRequests() {
    yield takeLatest(USERLIST_REQUEST, requestUserlist);
}

export function* watchUserSave() {
    yield takeLatest(USER_SAVE, saveUser);
}

export function* watchUserEdit() {
    yield takeLatest(USER_UPDATE, updateUser);
}

export default function* usersSaga() {
    yield all([
        watchUserlistRequests(),
        watchUserSave(),
        watchUserEdit(),
    ]);
}