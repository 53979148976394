import { ROLES_REQUEST, ROLES_LOAD, ROLES_ERROR, PERMISSIONS_LOAD } from "../actions/roles.actions";
import produce from 'immer';

const initialState = {
    loading: false,
    error: null,
    roles: [],
    permissions: [],
}

export default (state = initialState, action) =>
    produce (state, draft => {
        switch (action.type) {
            case ROLES_REQUEST:
                draft.loading = true;
            break;
            case ROLES_LOAD:
                draft.loading = false;
                draft.roles = action.payload.roles;
            break;
            case ROLES_ERROR:
                draft.loading = false;
                draft.error = action.payload.error;
            break;
            case PERMISSIONS_LOAD:
                draft.permissions = action.payload.permissions;
            break;
            default:
                return state
        }
    })