export const POSTHISTORY_GET = 'POSTHISTORY_GET';
export const POSTHISTORY_GET_VERSION = 'POSTHISTORY_GET_VERSION';
export const POSTHISTORY_LOAD = 'POSTHISTORY_LOAD';
export const POSTHISTORY_LOAD_VERSION = 'POSTHISTORY_LOAD_VERSION';
export const POSTHISTORY_LOADING = 'POSTHISTORY_LOADING';
export const POSTHISTORY_VERSION_LOADING = 'POSTHISTORY_VERSION_LOADING';

export function setPostHistoryLoading(loading)
{
    return {
        type: POSTHISTORY_LOADING,
        payload: {loading}
    }
}

export function setPostLoading(loading)
{
    return {
        type: POSTHISTORY_VERSION_LOADING,
        payload: {loading}
    }
}

export function getPostHistory(id)
{
    return {
        type: POSTHISTORY_GET,
        payload: {id}
    }
}

export function loadPostHistory(history)
{
    return {
        type: POSTHISTORY_LOAD,
        payload: {history},
    }
}

export function getPostVersion(id, version)
{
    return {
        type: POSTHISTORY_GET_VERSION,
        payload: {
            id,
            version
        }
    }
}

export function loadPostVersion(version)
{
    return {
        type: POSTHISTORY_LOAD_VERSION,
        payload: {version}
    }
}