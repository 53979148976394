import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import jwt from "jsonwebtoken";
import moment from "moment";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { refreshLogin } from "../../actions/auth.actions";

export const SessionChecker = () => {

    let token = useSelector(state => state.auth.token);

    let dispatch = useDispatch();

    useEffect(() => {
        let handleRefresh = () => {
            dispatch(refreshLogin())
        }

        let checkSessionTime = () => {
            let decoded = jwt.decode(token);
            let now = moment();
            let expires = moment.unix(decoded.exp);
    
            let minutesLeft = expires.diff(now, 'minute');
            
    
            if (minutesLeft <= 15) {
                toast.warn(
                    <div>
                        <span role="img" aria-label="Reloj">⏱</span> Tu sesión expira {now.to(expires)}
                        <Button color="secondary" onClick={handleRefresh}>Renovar sesión</Button>
                    </div>
                );
            }
        };

        checkSessionTime();
        const interval = setInterval(checkSessionTime, 60000);

        return () => clearInterval(interval);
    }, [token, dispatch]);

    return (<></>)
}

export default SessionChecker;