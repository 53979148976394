import { put, takeLatest, call, all } from "redux-saga/effects";
import { LAYOUTS_REQUEST, LAYOUT_REQUEST, LAYOUT_SAVE, loadLayouts, LAYOUT_ADD, loadEmptyLayout, LAYOUT_UPDATE, requestLayouts   } from "../actions/layout.actions";
import api from "../api";
import { push } from "connected-react-router";

export function* doRequestLayouts()
{
    let response = yield call(api.getLayouts);
    if (response.success) {
        yield put(loadLayouts(response.data));
    }
}

export function* requestLayout(action)
{

}

export function* addLayout()
{
    yield put(loadEmptyLayout());
    yield put(push('/dashboard/admin/layouts/add'));
}

export function* saveLayout(action)
{
    let layout = action.payload.layout;

    let response = yield call(api.createLayout, layout);
    
    if (response.success === true) {
        yield put(requestLayouts());
        yield put(push('/dashboard/admin/layouts'));
    }

}

export function* updateLayout({id, layout})
{
    let response = yield call(api.updateLayout, id, layout);
    console.log(response);
}

export function* watchLayoutsRequest() {
    yield takeLatest(LAYOUTS_REQUEST, doRequestLayouts);
}

export function* watchLayoutRequest() {
    yield takeLatest(LAYOUT_REQUEST, requestLayout);
}

export function* watchLayoutAdd() {
    yield takeLatest(LAYOUT_ADD, addLayout);
}
 
export function* watchLayoutSave()
{
    yield takeLatest(LAYOUT_SAVE, saveLayout)
}

export function* watchLayoutUpdate()
{
    yield takeLatest(LAYOUT_UPDATE, updateLayout)
}

export default function* layoutSaga() {
    yield all([
        watchLayoutsRequest(),
        watchLayoutRequest(),
        watchLayoutAdd(),
        //watchLayoutEdit(),
        watchLayoutSave(),
        watchLayoutUpdate(),
    ]);
}