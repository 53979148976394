import produce from 'immer';
import { SECTIONS_LOAD, SECTIONS_REQUEST, SECTION_SAVE_LOADING, SECTION_UPDATING, SECTION_LOCAL_UPDATE } from "../actions/sections.actions";

const initialState = {
    sections: [],
    currentSection: {
        name: '',
        slug: ''
    },
    loading: false,
    save_loading: false,
    isEditing: false
};

export default (state = initialState, action) =>
produce (state, draft => {
    switch (action.type) {
        case SECTIONS_REQUEST:
            draft.loading = true;
        break;
        case SECTIONS_LOAD:
            draft.loading = false;
            draft.save_loading = false;
            draft.isEditing = false;
            draft.sections = action.payload.sections;
        break;
        case SECTION_UPDATING:
            draft.currentSection = state.sections.find((item) => item.id === action.payload.id);
            draft.isEditing = true;
            break;
        case SECTION_LOCAL_UPDATE:
            draft.currentSection = action.payload.section;
            break;
        case SECTION_SAVE_LOADING:
            draft.save_loading = true;
            break;
        default:
            return state
    }
})