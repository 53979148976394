import {
    DO_LOGIN,
    DO_LOGOUT,
    LOGIN_ERROR,
    LOGIN_ATTEMPT,
    SET_PERMISSIONS,
    SET_PROFILE,
    SET_LOGGING_IN,
} from "../actions/auth.actions";
import produce from 'immer';

const initialState = {
    isAuthenticated: false,
    isLoggingIn: false,
    loginError: null,
    token: null,
    permissions: [],
    profile: null,
}

export default (state = initialState, action) =>
    produce (state, draft => {
        switch (action.type) {
            case SET_LOGGING_IN:
                draft.isLoggingIn = action.payload.status;
            break;
            case LOGIN_ATTEMPT:
                draft.loginError = null;
            break;
            case DO_LOGIN:
                draft.isAuthenticated = true;
                draft.token = action.payload.token;
                draft.loginError = null;
            break;
            case LOGIN_ERROR:
                draft.loginError = action.payload.error;
            break;
            case SET_PERMISSIONS:
                draft.permissions = action.payload.permissions;
            break;
            case SET_PROFILE:
                draft.profile = action.payload.profile;
            break;
            case DO_LOGOUT:
                draft.token = null;
                draft.permissions = [];
                draft.loginError = null;
                draft.profile = null;
            break;
            default:
                return state
        }
    })