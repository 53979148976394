export const LAYOUTS_REQUEST = "LAYOUTS_REQUEST";
export const LAYOUTS_LOAD = "LAYOUTS_LOAD";
export const LAYOUT_REQUEST = "LAYOUT_REQUEST";
export const LAYOUT_ADD_BLOCK = "LAYOUT_ADD_BLOCK";
export const LAYOUT_UPDATE_BLOCK = "LAYOUT_UPDATE_BLOCK";
export const LAYOUT_UPDATE_KEY = "LAYOUT_UPDATE_KEY";
export const LAYOUT_ADD = "LAYOUT_ADD";
export const LAYOUT_EDIT = "LAYOUT_EDIT";
export const LAYOUT_LOAD_EMPTY = "LAYOUT_LOAD_EMPTY";
export const LAYOUT_LOAD = "LAYOUT_LOAD";
export const LAYOUT_SAVE = "LAYOUT_SAVE";
export const LAYOUT_UPDATE = "LAYOUT_UPDATE";
export const LAYOUT_SAVING = "LAYOUT_SAVING";
export const LAYOUT_SAVED = "LAYOUT_SAVED";


export function requestLayouts()
{
    return {
        type: LAYOUTS_REQUEST
    }
}

export function loadLayouts(layouts)
{
    return {
        type: LAYOUTS_LOAD,
        payload: {
            layouts
        }
    }
}

export function requestLayout(key)
{
    return {
        type: LAYOUT_REQUEST,
        payload: {
            key
        }
    }
}

export function loadLayout(layout)
{
    return {
        type: LAYOUT_LOAD,
        payload: {
            layout
        }
    }
}

export function loadEmptyLayout()
{
    return {
        type: LAYOUT_LOAD_EMPTY
    }
}

export function addLayout() {
    return {
        type: LAYOUT_ADD
    }
}

export function editLayout(id)
{
    return {
        type: LAYOUT_EDIT,
        payload: {
            id
        }
    }
}

export function addLayoutBlock(index)
{
    return {
        type: LAYOUT_ADD_BLOCK,
        payload: {
            index
        }
    }
}

export function updateLayoutKey(key)
{
    return {
        type: LAYOUT_UPDATE_KEY,
        payload: {
            key
        }
    }
}

export function updateLayoutBlock(index, data)
{
    return {
        type: LAYOUT_UPDATE_BLOCK,
        payload: {
            index,
            data
        }
    }
}

export function saveLayout(layout)
{
    return {
        type: LAYOUT_SAVE,
        payload: {
            layout
        }
    }
}

export function updateLayout(id, layout)
{
    return {
        type: LAYOUT_UPDATE,
        payload: {
            id,
            layout
        }
    }
}

export function savingLayout()
{
    return {
        type: LAYOUT_SAVING
    }
}

export function savedLayout()
{
    return {
        type: LAYOUT_SAVED
    }
}