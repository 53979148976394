import React, { Suspense, lazy } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Container } from "reactstrap";
import Sidebar from './dashboard.sidebar';
import Header from './dashboard.header';
import './dashboard.layout.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import AppVersion from "../AppVersion";
import SessionChecker from "../containers/auth/SessionChecker";

const UsersPage = lazy(() => import("../pages/users.page"));
const RolesPage = lazy(() => import("../pages/roles.page"));
const PostsPage = lazy(() => import("../pages/posts.page"));
const SectionsPage = lazy(() => import("../pages/sections.page"));
const ConfigurationPage = lazy(() => import("../pages/configuration.page"));
const LayoutPage = lazy(() => import("../pages/layouts.page"));

export const DashboardLayout = () => {
    return (
        <div className="app">
            <Header />
            <div className="app-body">
                <Sidebar />

                <main className="main">
                    {/*<AppBreadcrumb appRoutes={routes}/>*/}
                    {/*<AppBreadcrumb appRoutes={routes} router={router}/>*/}
                    <Container fluid>
                        <Suspense fallback={<div> Cargando... </div>}>
                            <Switch>
                                <Route path="/dashboard/users" component={UsersPage}/>
                                <Route path="/dashboard/roles" component={RolesPage}/>
                                <Route path="/dashboard/posts" component={PostsPage}/>
                                <Route path="/dashboard/sections" component={SectionsPage}/>
                                <Route path="/dashboard/admin/configuration" component={ConfigurationPage}/>
                                <Route path="/dashboard/admin/layouts" component={LayoutPage}/>
                                <Redirect from="/" to="/dashboard" />
                            </Switch>
                        </Suspense>
                        <ToastContainer
                            position='bottom-right'
                        />
                    </Container>
                </main>
            </div>
            <footer className="app-footer">
                <AppVersion />
                <SessionChecker />
            </footer>
        </div>
    );
}