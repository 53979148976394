import { useState } from "react";

export const useFormInput = (defaultValue) => {
    const [value, setValue] = useState(defaultValue);

    function handleChange(e) {
        setValue(e.target.value);
    }

    return {
        value,
        onChange: handleChange
    }
}