import { put, takeLatest, call, all } from "redux-saga/effects";
import { push } from "connected-react-router";
import { POSTHISTORY_GET, loadPostHistory, setPostHistoryLoading, getPostVersion, POSTHISTORY_GET_VERSION, loadPostVersion, setPostLoading } from "../actions/posthistory.actions";
import api from "../api";
import { toast } from "react-toastify";
import { TOAST_ERROR_OPTIONS } from "../constants/toastify";

export function* doGetPostHistory(action)
{
    yield put(setPostHistoryLoading(true));
    let response = yield call(api.getPostHistory, action.payload.id);

    if (response.success) {
        yield put(loadPostHistory(response.data));
        yield put(getPostVersion(action.payload.id, response.data.length));
        yield put(setPostHistoryLoading(false));
    } else {
        toast.error(response.message, TOAST_ERROR_OPTIONS);
    }

}

export function* doGetPostVersion(action)
{
    yield put(setPostLoading(true));
    let response = yield call(api.getPostVersion, action.payload.id, action.payload.version);

    if (response.success) {
        yield put(loadPostVersion(response.data));
        yield put(setPostLoading(false));
    } else {
        toast.error(response.message, TOAST_ERROR_OPTIONS);
    }
}

export function* watchHistoryRequest()
{
    yield takeLatest(POSTHISTORY_GET, doGetPostHistory);
}

export function* watchHistoryVersionRequest()
{
    yield takeLatest(POSTHISTORY_GET_VERSION, doGetPostVersion);
}

export default function* posthistorySaga() {
    yield all([
        watchHistoryRequest(),
        watchHistoryVersionRequest(),
    ]);
}