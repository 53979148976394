import produce from 'immer';
import { POSTS_LOAD } from '../actions/posts.actions';

const initialState = {
    posts: [],
};

export default (state = initialState, action) =>
produce (state, draft => {
    switch (action.type) {
        case POSTS_LOAD:
            draft.posts = action.payload.posts;
        break;
        default:
            return state
    }
})