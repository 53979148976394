import React from 'react';
import './App.css';
import { LoginLayout } from "./layouts/login.layout";
import { DashboardLayout } from "./layouts/dashboard.layout";
import { Switch, Route } from 'react-router-dom';
import ProtectedRoute from './containers/ProtectedRoute';
import ForbiddenPage from './pages/forbidden.page';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"


function App() {
  return (
    <Switch>
      <Route exact path="/" component={LoginLayout} />
      <ProtectedRoute path="/dashboard" component={DashboardLayout} />
      <ProtectedRoute path="/forbidden" component={ForbiddenPage} />
    </Switch>
  );
}

export default App;
